<template>
  <q-btn style="float: left;" color="primary" label="Add Action Route Point" @click="showDialog = true"/>

  <q-dialog v-model="showDialog" class="ap-dialog">
    <q-card class="ap-card">
      <q-card-section>
        <div class="text-h6">Please fill in values for Route Action Point</div>
        <q-input v-for="(input, index) in inputs" :key="index" filled
                 v-model="input.model"
                 :label="input.label"
                 :rules="input.rules"/>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup/>
        <q-btn flat label="OK" color="primary" :disable="!areInputsValid()"  @click="handleOk"/>
      </q-card-actions>
      <ConfirmationDialogBox @confirm="sendActionPoint" ref="confirmBox"/>
    </q-card>
  </q-dialog>
</template>

<script>
import ConfirmationDialogBox from './ConfirmationDialogBox.vue'
export default {
  name: 'AddRouteActionPointDialog',
  components:{
    ConfirmationDialogBox
  },
  inheritAttrs: false,
  props: {
    checkActionPoint: {
      type: Function
    }
  },
  data() {
    return {
      showDialog: false,
      point: null,
      inputs: [
        {
          label: 'Route Action Point Id',
          model: '',
          rules: [val => (val && val.length > 0 && !isNaN(val)) || 'ID is required and should be a positive integer']
        },
        {
          label: 'Route Action Point External Reference',
          model: '',
          //hack to have a rule
          rules: [val => val.length < 1000 || 'Too large']
        },
        {
          label: 'Route Action Point Radius',
          model: '',
          rules: [val => (!val || (!isNaN(val) && val > 0)) || 'Radius, if provided, must be a positive number']
        },
        {
          label: 'Latitude',
          model: '',
          rules: [val => (val.length > 0 && !isNaN(val) && /\d+\.\d+/.test(val)) || 'Latitude is required and must be floating number']
        },
        {
          label: 'Longitude',
          model: '',
          rules: [val => (val.length > 0 && !isNaN(val) && /\d+\.\d+/.test(val)) || 'Longitude is required and must be floating number']
        },
        {
          label: 'Route Action Point Time To Act',
          model: '',
          rules: [val => (!val || (!isNaN(val) && val > 0)) || 'Time To Act, if provided, must be positive number']
        },
        {
          label: 'Route Action Point Required Action',
          model: '',
          rules: [val => (!isNaN(val) && val > 0) || 'Required Action is required and must be positive number']
        },
        {
          label: 'Route Action Point Required Action Description',
          model: '',
          rules: [val => (val.trim().length > 0) || 'Description is required']
        }
      ]
    }
  },
  methods: {
    handleOk() {
      let routeActionPoint = {};
      this.inputs.forEach(input => {
        let key = input.label.split(" ").join("");
        key = key.charAt(0).toLowerCase() + key.slice(1);
        routeActionPoint[key] = input.model;
      });

      const response = this.checkActionPoint(routeActionPoint.routeActionPointId);
      if(!response){
        this.$emit('routeActionPoint', routeActionPoint);
        this.showDialog = false;
      }else{
        this.point = routeActionPoint;
        this.$refs.confirmBox.showBox();
      }
    },
    sendActionPoint(){
      if(this.point){
        this.$emit('routeActionPoint', this.point);
        this.showDialog = false;
        this.point = null
      }
    },
    areInputsValid() {
      return this.inputs.every(input => input.rules.every(rule => rule(input.model) === true));
    },
    updateRouteActionPoint(actionPoint){
      this.inputs.forEach(input => {
        let key = input.label.split(" ").join("");
        key = key.charAt(0).toLowerCase() + key.slice(1);
        input.model = actionPoint[key];
      });
      this.showDialog = true;
    }
  }
}
</script>
<style scoped>
.ap-dialog {
  width: 100vw;
  max-width: unset !important;
}

.ap-card {
  width: 800px;
}
</style>