<template>
    <q-dialog v-model="showDialog" class="ap-dialog">
      <q-card>
        <q-card-section>
          <div class="q-mb-md">A point with the same ID already exists. This point will be overwritten. <br>Do you want to continue?</div>
        </q-card-section>
        <q-card-actions>
          <q-btn label="Yes" color="primary" @click="confirm" />
          <q-btn label="No" color="red-5" @click="cancel" />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<style scoped>
.ap-dialog {
    width: 300px;
    height: 300px;
}
</style>

<script>
export default {
  name: 'ConfirmationDialogBox',
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.showDialog = false;
    },
    cancel() {
      this.showDialog = false;
    },
    showBox(){
        this.showDialog = true;
        console.log("Hello");
    }
  }
};
</script>


