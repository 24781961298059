<script>
export default {
  name: "ErrorDialog",
  props: ["errorMessage"],
  data() {
    return {
      showErrorDialog: false
    }
  }
}
</script>

<template>
  <q-dialog v-model="showErrorDialog" class="er-dialog">
    <q-card class="er-card">
      <q-card-section>
        <div class="text-h6">Error</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
      {{ errorMessage }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="OK" color="negative" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.er-dialog {
  width: 40vw;
  max-width: unset !important;
}
.er-card {
  width: 400px;
}
</style>