<template>
    <div class="header">
      <img src="../assets/logo.png" alt="Logo" class="logo">
      <h2>S-421 Converter</h2>
      <div class="right-section">
        <span class="operated-by">Operated by</span>
        <a href="https://www.ecc.no" target="_blank">
          <img src="../assets/company_logo.png" alt="Company Logo" class="logo">
        </a>
      </div>
    </div>
    <q-form @submit="submitForm">
      <div class="q-pa-md">
      <q-card class="q-pa-md col-md-5">
        <q-card-section>
          <q-file
                  filled
                  use-input
                  label="Upload RTZ"
                  accept=".rtz"
                  @update:modelValue="handleFileChange($event)"
          >
            <template v-slot:prepend>
              <div>{{ filename }}</div>
            </template>
          </q-file>
        </q-card-section>
      </q-card>
      </div>

      <div class="row q-pa-md">

      <div class="col-12 col-md-6">
        <q-card class="q-ma-md">
          <q-card-section>
          <div class="text-left">Route Details</div>
          <q-input
              filled
              v-model="route.routeFormatVersion"
              label="Route Format Version *"
              :rules="[val => !!val || 'Route Format Version is required']"
          />
          <q-input
              filled
              v-model="route.routeId"
              label="Route ID *"
              :rules="[val => !!val || 'Route ID is required']"
          />
          <q-input
              type="number"
              filled
              v-model="route.routeEditionNo"
              label="Route Edition No *"
              :rules="[val => (!!val && !isNaN(val) && val >= 0) || 'Route Edition Number is required']"
          />
          <div class="text-left">Route Info</div>
          <q-input
              filled
              v-model="routeInfo.routeInfoName"
              label="Route Info Name *"
              :rules="[val => !!val || 'Route Info Name is required']"
          />
          <div class="text-right text-caption">
            Mandatory elements
          </div>
        </q-card-section>
      </q-card>
      </div>
      <div class="col-12 col-md-6">
        <q-card class="q-ma-md">
          <q-card-section>
              <div class="text-left">Route Info</div>
              <q-input filled v-model="routeInfo.routeInfoAuthor"
                       label="Route Info Author"
                       :rules="[val => val.length < 1000 || 'Too large']"/>
              <q-input
                  type="number"
                  filled
                  v-model="routeInfo.routeInfoStatus"
                  label="Route Info Status"
                  :rules="[val => (!isNaN(val) && val >= 0) || 'Must be a positive number']"
              />
              <q-input
                  type="number"
                  filled
                  v-model="routeInfo.routeInfoLengthMax"
                  label="Route Info Length Max"
                  :rules="[val => (!isNaN(val) && val >= 0) || 'Must be a positive number']"
              />
              <q-input
                  type="number"
                  filled
                  v-model="routeInfo.routeInfoDraftMax"
                  label="Route Info Draft Max"
                  :rules="[val => (!isNaN(val) && val >= 0) || 'Must be a positive number']"
              />
              <q-input
                  type="number"
                  filled
                  v-model="routeInfo.routeInfoAirDraftMax"
                  label="Route Info Air Draft Max"
                  :rules="[val => (!isNaN(val) && val >= 0) || 'Must be a positive number']"
              />
              <q-input filled v-model="routeInfo.routeInfoDescription" label="Route Info Description" />
            </q-card-section>
          </q-card>
          </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-5 q-ma-md q-pa-md">
          <AddRouteActionPointDialog v-model="dialogVisible" @routeActionPoint="handleInputData" ref="actionPointDialog" :checkActionPoint="checkIfActionPointExists"/>
        </div>
        <div v-if="routeActionPoints.length > 0" >
          <draggable v-model="routeActionPoints" class="drag-area" handle=".drag-handle">
            <transition-group>
              <q-card
                  v-for="(routeActionPoint, index) in routeActionPoints"
                  :key="index"
                  class="q-ma-md"
                  clickable
              >
                <q-card-section>
                  <span>Route Action Point ID: {{ routeActionPoint.routeActionPointId }}, External Reference: {{ routeActionPoint.routeActionPointExternalReference }},
                    Radius: {{ routeActionPoint.routeActionPointRadius }},
                    Latitude: {{ routeActionPoint.latitude}}, Longitude: {{routeActionPoint.longitude}},
                    Time to act: {{ routeActionPoint.routeActionPointTimeToAct }}, Action: {{ routeActionPoint.routeActionPointRequiredAction }},
                    Description: {{ routeActionPoint.routeActionPointRequiredActionDescription }}
                  </span>
                  <q-btn class="q-ml-sm drag-handle" flat  round color="grey-8" icon="drag_indicator"><q-tooltip>Drag</q-tooltip></q-btn> 
                  <q-btn flat round icon="edit" @click="updateRouteActionPoint(index)"><q-tooltip>Edit</q-tooltip></q-btn>
                  <q-btn flat round color="red" icon="delete" @click="deleteRouteActionPoint(index)"><q-tooltip>Delete</q-tooltip></q-btn>
                </q-card-section>
              </q-card>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="col-12 col-md-6 q-ma-md q-pa-md">
        <q-btn type="submit" color="primary" label="Submit" class="full-width"/>
      </div>
    </q-form>
    <ErrorDialog :error-message="errorMessage" v-model="showErrorDialog"></ErrorDialog>
</template>
<style scoped>
.header {
  display: flex;
  align-items: center; /* Vertically aligns items */
  justify-content: space-between; /* Aligns items between left and right */
  padding: 10px;
}

.logo {
  height: 80px;
}

/* Title stays aligned to the left after the first logo */
.header h2 {
  margin: 0; /* Remove default margins */
  margin-left: 20px; /* Space between the first logo and the title */
  flex-grow: 1; /* Fills the available space */
  text-align: left;
}

/* Right section holding the "Operated by" text and logo */
.right-section {
  display: flex;
  align-items: center;
  margin-right: 16px; /* Optional margin from the right */
}

.operated-by {
  margin-right: 10px; /* Space between text and logo */
  font-size: 14px; /* Smaller font for the "operated by" text */
  color: gray; /* Optional color */
}


</style>
<script>
import axios from "axios";
import AddRouteActionPointDialog from "@/components/AddRouteActionPointDialog.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
import { VueDraggableNext } from 'vue-draggable-next';
export default {
  name: 'RTZFileUpload',
  components: {
    AddRouteActionPointDialog,
    ErrorDialog,
    draggable: VueDraggableNext
  },
  data() {
    return {
      file: '',
      base64file: '',
      filename: '',
      route: {
        routeFormatVersion: '',
        routeId: '',
        routeEditionNo: ''
      },
      routeInfo: {
        routeInfoName: '',
        routeInfoAuthor: '',
        routeInfoStatus : '',
        routeInfoLengthMax: '',
        routeInfoDraftMax: '',
        routeInfoAirDraftMax: '',
        routeInfoDescription: ''
      },
      routeActionPoints:[],
      selectedRouteActionPoint: null,
      dialogVisible: false,
      showErrorDialog: false,
      errorMessage: ''
    }
  },
  methods: {
    checkIfActionPointExists(routeActionPointId){
      for(let point of this.routeActionPoints){
        if(point.routeActionPointId === routeActionPointId){
          return true;
        }
      }
      return false;
    },
    updateRouteActionPoint(index){
      this.$refs.actionPointDialog.updateRouteActionPoint(this.routeActionPoints[index]);
    },
    deleteRouteActionPoint(index) {
      this.routeActionPoints.splice(index, 1)
    },
    showDialog() {
      this.dialogVisible = true
    },
    handleInputData(data) {
      console.log('Received:', data);
      for(let point of this.routeActionPoints){
        if(point.routeActionPointId === data.routeActionPointId){
          point.latitude = data.latitude;
          point.longitude = data.longitude;
          point.routeActionPointExternalReference = data.routeActionPointExternalReference;
          point.routeActionPointRadius = data.routeActionPointRadius;
          point.routeActionPointTimeToAct = data.routeActionPointTimeToAct;
          point.routeActionPointRequiredAction = data.routeActionPointRequiredAction;
          point.routeActionPointRequiredActionDescription = data.routeActionPointRequiredActionDescription;
          return;
        }
      }
      this.routeActionPoints.push(data)
    },
    submitForm() {
      let data = JSON.stringify({
        file: this.base64file,
        filename: this.filename,
        extraAttributes: {
          route: this.route,
          routeInfo: this.routeInfo,
          routeActionPoints: this.routeActionPoints
        }
      });
      axios.post(
          'https://s6doczthbi.execute-api.eu-west-1.amazonaws.com/prod/convert/rtz/s421',
          data,
          {
            responseType: 'json',
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(response) {
        let base64 = response.data.fileData;
        let newFilename = response.data.fileName;
        let decodedData = atob(base64);
        let array = [];

        for(let i = 0; i < decodedData.length; i++) {
          array.push(decodedData.charCodeAt(i));
        }

        let file = new Blob([new Uint8Array(array)], {type: 'application/octet-stream'});

        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = newFilename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

      }).catch(function(error) {
        this.errorMessage = 'File upload Failed: ' + error.message
        console.log(this.errorMessage)
        this.showError(this.errorMessage)
      }.bind(this));
    },
    async handleFileChange(file) {
      console.log(file.name);
      this.file = file;
      this.filename = file.name;
      try {
        this.base64file = await this.convertFileToBase64(file);
      } catch(e) {
        console.error(e);
        this.errorMessage = e;
        this.showError();
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split(',')[1];
          resolve(base64Data);
        }
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    showError () {
      this.showErrorDialog = true
    },
  },
}
</script>